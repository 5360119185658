import React from 'react'
import { imagesArr } from '../../assets'

export const MultipleTitlePointCard = ({ title, descriptions, image, direction }) => {
  return (
    <div className={`point-card-container ${direction}`}>
      <img src={image} alt="" />
      <div className='side'>
        <h3>{title}</h3>
        <ul>
          {
            descriptions.map((desc, index) => (
              <li key={index} className="column">
                <img className='check' src={imagesArr.Check} alt='' />
                <p>{desc.description}</p>
              </li>
            ))
          }
        </ul>
      </div>
    </div>
  )
}