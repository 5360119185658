import React from "react";
import { imagesArr } from "../../../assets";
import { Avatar } from "./Avatar";

export const ChatListItems = ({ selectedClient, user, animationDelay, active, isOnline, image }) => {
    const selectChat = () => {
        selectedClient(user)
    }

    return (
        <>
            <div style={{ animationDelay: `0.${animationDelay}s` }} onClick={selectChat} className={`chatlist__item ${active ? active : ""}`}>
                <div className="user-information">
                    <Avatar image={image ? image : imagesArr.UserIcon} isOnline={isOnline} />
                    <div className="userMeta">
                        <p>{user.email}</p>
                        <span>Hey, there I'm available</span>
                    </div>
                </div>
                {
                    user.unread_count > 0 ? <p>{user.unread_count}</p> : null
                }
                {/* <p>{user.unread_count > 0}</p> */}
            </div>
        </>
    )
}