import React, { useState, useRef, useEffect } from 'react'
import { io } from "socket.io-client";
import Cookies from 'js-cookie'
import { imagesArr } from '../../../assets';
import './Message.css'

export const Message = ({ setText, setActive }) => {

    const [msg, setMsg] = useState('');
    const [flag, setFlag] = useState(Cookies.get('convoId') ? true : false);
    const [ack, setAck] = useState(undefined)
    const socket = useRef(null);
    const id = useRef(Cookies.get('convoId'));

    useEffect(() => {

        if (ack !== undefined) {
            setText({ msg: msg, sentByClient: true, ack: ack });
            setAck(undefined);
            setMsg('');
        }

    }, [ack])

    if (!socket.current) {

        socket.current = io.connect(process.env.REACT_APP_ORIGIN, {
            auth: {
                'id': Cookies.get('convoId'),
                'type': 'c'
            }
        })

        socket.current.on("clientResponse", (data) => {
            setAck(data)
        });

        socket.current.on("newMessage", (data) => {
            setText({ msg: data.msg, sentByClient: false, ack: true });
        });

        socket.current.on("receiveConversationId", (data) => {
            id.current = data.id;
            Cookies.set('convoId', data.id, { expires: 7 });

            const isSet = Cookies.get('jwt');

            if (!isSet) {
                Cookies.set('jwt', data.jwt, { expires: 7 });
            }

            setFlag(true);
            setActive(true);
        });
    }

    const sendMessage = () => {
        socket.current.emit(flag ? "clientMessage" : "startConversation", flag ? { convoId: Cookies.get('convoId'), msg: msg, type: true } : msg);
    }

    return (
        <div className='wrapper'>
            <textarea type='text' value={msg} name='message' placeholder={flag ? 'Write a Message' : 'Enter email address'} onChange={(e) => setMsg(e.target.value)} />
            <button onClick={() => sendMessage()}>
                <img src={imagesArr.SendMessage} alt="Send" />
            </button>
        </div>
    )
}