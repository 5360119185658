import "./SoftwareCard.css"

export const SoftwareCard = ({ image, name })=>{
    return (
        <>
            <div className='card-container'>
                <img loading="lazy" src={image} alt={name} />
            </div>
        </>
    )
}