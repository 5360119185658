import React from 'react'

export const ChatItem = ({ user, msg, animationDelay, acknowledgment }) => {    
    return (
        <div style={{ animationDelay: `0.${animationDelay}s` }} className={`chat__item ${user ? user : ""}`}>
            <div className='chat__item__content'>
                <div className='chat__msg'>{msg}</div>
            </div>
            <span className={`ack-${user === "other" ? 'left' : 'right'} status-${acknowledgment ? 'green': 'red' }`}>{acknowledgment ? 'Sent' : 'Failed'}</span>
        </div>
    )
}