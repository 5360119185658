import Close from "./svg/close.svg"
import Phone from "./svg/phone.svg"
import Email from "./svg/envelope.svg"
import Map from "./svg/map.svg"
import Facebook from "./svg/facebook.svg"
import Twitter from "./svg/twitter.svg"
import Instagram from "./svg/instagram.svg"
import Linkedin from "./svg/linkedin.svg"
import Youtube from "./svg/youtube.svg"
import FacebookOutlined from "./svg/facebook-outlined.svg"
import Hamburger from "./svg/bar.svg"
import Stethoscope from "./svg/stethoscope.svg"
import SendMessage from "./svg/sendMessage.svg"
import HomePageBg from "./HomePageBg.jpg"
import Service from "./service.jpg"
import TestimonialBg from "./Testimonial.jpg"
import defaultUserIcon from "./60.png"
import UserIcon from "./svg/user.svg"
import LightBulb from "./svg/lightbulb.svg"
import Handshake from "./svg/handshake.svg"
import Podcast from "./svg/podcast.svg"
import AboutAdvantages from "./about-advantages.jpg"
import Check from "./svg/check.svg"
import Team1 from "./people/team1.jpg"
import Team2 from "./people/team2.jpg"
import Team3 from "./people/team3.jpg"
import Team4 from "./people/team4.jpg"
import Specialty1 from "./specialties/s1.jpg"
import Specialty2 from "./specialties/s2.jpg"
import Specialty3 from "./specialties/s3.jpg"
import Specialty4 from "./specialties/s4.jpg"
import Specialty5 from "./specialties/s5.jpg"
import Specialty6 from "./specialties/s6.jpg"
import Building from "./svg/building.svg"
import Calendar from "./svg/calendar.svg"
import Eye from "./svg/eye.svg"
import Globe from "./svg/globe.svg"
import HeartPulse from "./svg/heart-pulse.svg"
import Pencil from "./svg/pencil.svg"
import ShieldHalved from "./svg/shield-halved.svg"
import SignLanguage from "./svg/sign-language.svg"
import Snowflake from "./svg/snowflake.svg"
import Superpowers from "./svg/superpowers.svg"
import Users from "./svg/users.svg"
import AngleDown from "./svg/angle-down.svg"
import PaperPlane from "./svg/paper-plane.svg"
import Plus from "./svg/plus.svg"
import Search from "./svg/search.svg"
import Settings from "./svg/settings.svg"
import AngleUp from "./svg/angle-up.svg"
import Bot from "./svg/bot.svg"
import Message from "./svg/message.svg"
import AngleLeft from "./svg/angle-left.svg"
import Logout from "./svg/logout.svg"
import AnalyticsReporting from "./services/analytics-reporting.png"
import ChargesEntry from "./services/charges-entry.jpg"
import ClaimsRecheck from "./services/claims-recheck.jpg"
import Coding from "./services/coding.jpg"
import CredentialEnrollment from "./services/credential-enrollment.jpg"
import DenialApproval from "./services/denial-approval.jpg"
import PatientStatement from "./services/patient-statement.png"
import PaymentUpdating from "./services/payment-updating.jpg"
import MediFusion from "./medifusion.png"
import Kareo from "./kareo.svg"
import Claimmd from "./claimmd.png"
import Collaboratemd from "./collaboratemd.svg"
import Eclinical from "./eclinical.webp"
import Officeally from "./officeally.png"
import Practiceehr from "./practiceehr.svg"
import Procentive from "./procentive.png"
import RXNT from "./RXNT.webp"
import Perfect from "./perfect.svg"
import Citrix from "./citrix.png"
import AudiologySpeechTherapy from "./specialties/audiology-speech-therapy.jfif"
import CosmeticDentist from './specialties/cosmetic-dentist.jfif'
import Dietitian from './specialties/dietitian.jfif'
import HomeHealth from './specialties/home-health.jfif'
import Implantologist from './specialties/implantologist.jfif'
import InternalMedicine from './specialties/internal-medicine.jfif'
import MentalHealth from './specialties/mental-health.jfif'
import OralHealthTherapist from './specialties/oral-health-therapist.webp'
import Orthodontist from './specialties/orthodontist.jfif'
import Pediatrics from './specialties/pediatrics.jfif'
import Podiatry from './specialties/podiatry.jfif'
import Prosthodontist from './specialties/prosthodontist.jpg'
import Psychiatry from './specialties/psychiatry.jfif'
import Radiology from './specialties/radiology.jfif'
import MCS1 from './service-detail/Medical Coding Services/1.webp'
import MCS2 from './service-detail/Medical Coding Services/2.jpg'
import MCS3 from './service-detail/Medical Coding Services/3.jpg'
import MCS4 from './service-detail/Medical Coding Services/4.webp'
import MCS5 from './service-detail/Medical Coding Services/5.jpg'
import MBS1 from './service-detail/Medical Billing Services/1.jpg'
import MBS2 from './service-detail/Medical Billing Services/2.png'
import MBS3 from './service-detail/Medical Billing Services/3.png'
import MBS4 from './service-detail/Medical Billing Services/4.jpg'
import ICES1 from './service-detail/Insurance Credentialing & Enrollment Services/1.jpg'
import ICES2 from './service-detail/Insurance Credentialing & Enrollment Services/2.jpg'
import ICES3 from './service-detail/Insurance Credentialing & Enrollment Services/3.png'
import ICES4 from './service-detail/Insurance Credentialing & Enrollment Services/4.jpg'
import ICES5 from './service-detail/Insurance Credentialing & Enrollment Services/5.jpg'
import AR1 from './service-detail/AR Followup/1.png'
import AR2 from './service-detail/AR Followup/2.webp'
import RCM1 from './service-detail/Revenue Cycle Management/1.jpg'
import RCM2 from './service-detail/Revenue Cycle Management/2.jpg'
import Logo1 from './logo/logo.png'
import Logo2 from './logo/logo2.png'
import HomeIcon from './logo/home.jpg'

export const imagesArr = {
    HomeIcon,
    MedicalCodingService: {
        MCS1,
        MCS2,
        MCS3,
        MCS4,
        MCS5,
    },
    MedicalBillingService: {
        MBS1,
        MBS2,
        MBS3,
        MBS4,
    },
    InsuranceCredentialingEnrollmentServices: {
        ICES1,
        ICES2,
        ICES3,
        ICES4,
        ICES5,
    },
    ARFollowup: {
        AR1,
        AR2,
    },
    RevenueCycleManagement: {
        RCM1,
        RCM2,
    },
    Logo1,
    Logo2,
    AudiologySpeechTherapy,
    CosmeticDentist,
    Dietitian,
    HomeHealth,
    Implantologist,
    InternalMedicine,
    MentalHealth,
    OralHealthTherapist,
    Orthodontist,
    Pediatrics,
    Podiatry,
    Prosthodontist,
    Psychiatry,
    Radiology,
    PaymentUpdating,
    PatientStatement,
    DenialApproval,
    CredentialEnrollment,
    Coding,
    ClaimsRecheck,
    ChargesEntry,
    AnalyticsReporting,
    Logout,
    AngleLeft,
    Message,
    Bot,
    AngleUp,
    AngleDown,
    PaperPlane,
    Plus,
    Search,
    Settings,
    Users,
    Superpowers,
    Snowflake,
    SignLanguage,
    ShieldHalved,
    Pencil,
    HeartPulse,
    Globe,
    Eye,
    Calendar,
    Building,
    Specialty1,
    Specialty2,
    Specialty3,
    Specialty4,
    Specialty5,
    Specialty6,
    Team1,
    Team2,
    Team3,
    Team4,
    Check,
    AboutAdvantages,
    Podcast,
    Handshake,
    LightBulb,
    Close,
    Stethoscope,
    Phone,
    Email,
    Map,
    Facebook,
    Twitter,
    Instagram,
    Linkedin,
    Youtube,
    FacebookOutlined,
    Hamburger,
    SendMessage,
    HomePageBg,
    Service,
    TestimonialBg,
    defaultUserIcon,
    UserIcon,
    MediFusion,
    Kareo,
    Collaboratemd,
    Eclinical,
    Officeally,
    Practiceehr,
    Procentive,
    RXNT,
    Claimmd,
    Citrix,
    Perfect
}