import React from 'react'
import './TeamCard.css'

export const TeamCard = ({ image, name, profession }) => {
    return (
        <>
            <div className='card-container'>
                <img src={image} alt=''/>
                <div className='team-information'>
                    <p>{name}</p>
                    <span>{profession}</span>
                </div>
            </div>
        </>
    )
}