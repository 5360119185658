import React from 'react'
import { Link } from 'react-router-dom'
import { imagesArr } from '../../assets'
import { SpecialtyCard, SoftwareCard } from '../../components/componentIndex'

export const Specialties = () => {
    return (
        <>
            <div className='specialties-container'>
                <span>Specialties</span>
                <p>Our Team's Stronghold</p>

                <div className='all-specialties'>
                    <div className='specialties'>
                        <SpecialtyCard image={imagesArr.Prosthodontist} name={'Prosthodontist'}  />
                        <SpecialtyCard image={imagesArr.Orthodontist} name={'Orthodontist'}  />
                        <SpecialtyCard image={imagesArr.OralHealthTherapist} name={'Oral Health Therapist'}  />
                    </div>
                    <div className='specialties'>
                        <SpecialtyCard image={imagesArr.Podiatry} name={'Podiatry'}  />
                        <SpecialtyCard image={imagesArr.Pediatrics} name={'Pediatrics'}  />
                        <SpecialtyCard image={imagesArr.Psychiatry} name={'Psychiatry'}  />
                    </div>
                    <div className='specialties'>
                        <SpecialtyCard image={imagesArr.Implantologist} name={'Implantologist'}  />
                        <SpecialtyCard image={imagesArr.CosmeticDentist} name={'Cosmetic Dentist'}  />
                        <SpecialtyCard image={imagesArr.Radiology} name={'Radiology'}  />
                    </div>
                    <div className='specialties'>
                        <SpecialtyCard image={imagesArr.AudiologySpeechTherapy} name={'Audiology & Speech Therapy'}  />
                        <SpecialtyCard image={imagesArr.Specialty6} name={'DME'}  />
                        <SpecialtyCard image={imagesArr.MentalHealth} name={'Mental Health'}  />
                    </div>
                    <div className='specialties'>
                        <SpecialtyCard image={imagesArr.HomeHealth} name={'Home Health'}  />
                        <SpecialtyCard image={imagesArr.InternalMedicine} name={'Internal Medicine'}  />
                        <SpecialtyCard image={imagesArr.Dietitian} name={'Dietitian'}  />
                    </div>
                    <div className='not-found'>
                        <span>
                            If you don't find your relevant specialty, you can
                            <Link to={'/contact'}>contact us</Link>
                        </span>
                    </div>
                </div>
                <p>Softwares that we use</p>
                <div className='all-softwares'>
                    <div className='softwares'>
                        <SoftwareCard image={imagesArr.MediFusion} name={'Medifusion'}  />
                        <SoftwareCard image={imagesArr.Kareo} name={'Kareo'}  />
                        <SoftwareCard image={imagesArr.Collaboratemd} name={'Collaborate MD'}  />
                    </div>
                    <div className='softwares'>
                        <SoftwareCard image={imagesArr.RXNT} name={'RXNT'}  />
                        <SoftwareCard image={imagesArr.Citrix} name={'Citrix-Vision'}  />
                        <SoftwareCard image={imagesArr.Officeally} name={'Office Ally'}  />
                    </div>
                    <div className='softwares'>
                        <SoftwareCard image={imagesArr.Claimmd} name={'Claim MD'}  />
                        <SoftwareCard image={imagesArr.Procentive} name={'Procentive'}  />
                        <SoftwareCard image={imagesArr.Perfect} name={'Perfect'}  />
                    </div>
                    <div className='softwares'>
                        <SoftwareCard image={imagesArr.Eclinical} name={'eClinicalworks (ECW)'}  />
                        <SoftwareCard image={imagesArr.Practiceehr} name={'Practice EHR'}  />
                    </div>
                </div>
            </div>
        </>
    )
}