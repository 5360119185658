import React from 'react'
import './TestimonialCard.css'
import { imagesArr } from '../../assets'

export const TestimonialCard = ({ name, location, picture, review, title }) => {
  return (
    <div className='testimonial-container'>
      <div className='testimonial-information'>
        <span>{review ? review : 'Lorem ipsum dolor sit amet elit. hic odio tenetur. ante ipsum primis in faucibus orci luctus.Vivamus a ligula quam tesque et libero ut justo, ultrices in.'}</span>
      </div>
      <div className='user-information'>
        <hr />
        <div className='person-information'>
          <img src={picture ? picture : imagesArr.UserIcon} alt="Person" />
          <div className='person-detail'>
            <p>{name ? name : 'John Doe'}</p>
            <span>{location ? location : 'California'}</span>
          </div>
        </div>
      </div>
    </div>
  )
}
