import { Route, Routes, useLocation } from "react-router-dom"
import { ContactUs, Home, Login, Forget, Reset, Services, About, Specialties, Portal, ServiceDetail } from "../pages/pageIndex";
import { PrivateRoutes } from "./PrivateRoutes";
import React from 'react';

const AppRoutes = ({ setUserType }) => {
    const [service, setService] = React.useState({
        ServiceName: '',
        ServiceDescription: '',
        Points: [],
        Type: '',
    })

    const location = useLocation();
    React.useEffect(() => {
        if (location.pathname !== '/' && location.pathname !== '/services' &&
            location.pathname !== '/contact' && location.pathname !== '/about' &&
            location.pathname !== '/specialties' && location.pathname !== '/admin/forget' &&
            location.pathname !== '/admin/reset' && location.pathname !== '/admin/login' && location.pathname !== '/services/detail') {
            setUserType('a')
        }
        else {
            setUserType('c')
        }
    }, [location, setUserType])

    return (
        <Routes>
            <Route path="/">
                <Route path="services" element={<Services setService={setService} />} />
                <Route path="services/detail" element={<ServiceDetail ServiceName={service.ServiceName} ServiceDescription={service.ServiceDescription} Points={service.Points} Type={service.Type} />}/>
                <Route index element={<Home />} />
                <Route path="contact" element={<ContactUs />} />
                <Route path="about" element={<About />} />
                <Route path="specialties" element={<Specialties />} />

                <Route path="admin/">
                    <Route path="forget" element={<Forget />} />
                    <Route path="reset" element={<Reset />} />
                    <Route path="login" element={<Login />} />
                    <Route element={<PrivateRoutes />}>
                        <Route path="portal" element={<Portal />} />
                    </Route>
                </Route>

                <Route path="*" element={
                    <div style={{ display: "flex", justifyContent: "center", height: "100vh", alignItems: "center" }}>
                        <h1>404 Not Found</h1>
                    </div>
                } />

            </Route>
        </Routes>
    )
}

export default AppRoutes;