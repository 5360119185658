import React from 'react'
import './SpecialtyCard.css'

export const SpecialtyCard = ({ image, name }) => {
    return (
        <div className='card-container'>
            <img src={image} alt={name} />
            <div className='card-content'>
                <h3>{name}</h3>
            </div>
        </div>
    )
}