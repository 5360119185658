export const emailValidation = (email) => {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
}

export const passwordValidation = (password) => {
    const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
    return regex.test(String(password));
}

export const nameValidation = (name) => {
    const regex = /^[a-zA-Z ]{2,30}$/;
    return regex.test(String(name));
}

export const phoneValidation = (phone) => {
    const regex = /^[0-9]{10}$/;
    return regex.test(String(phone));
}

export const urlValidation = (url) => {
    const regex = /^(ftp|http|https):\/\/[^ "]+$/;
    return regex.test(String(url));
}

export const dateValidation = (date) => {
    const regex = /^([0-9]{2})\/([0-9]{2})\/([0-9]{4})$/;
    return regex.test(String(date));
}

export const timeValidation = (time) => {
    const regex = /^([0-9]{2}):([0-9]{2})$/;
    return regex.test(String(time));
}

export const numberValidation = (number) => {
    const regex = /^[0-9]+$/;
    return regex.test(String(number));
}

export const alphaNumericValidation = (alphaNumeric) => {
    const regex = /^[a-zA-Z0-9]*$/;
    return regex.test(String(alphaNumeric));
}

export const alphaNumericSpaceValidation = (alphaNumericSpace) => {
    const regex = /^[a-zA-Z0-9 ]*$/;
    return regex.test(String(alphaNumericSpace));
}

export const alphaNumericSpecialValidation = (alphaNumericSpecial) => {
    const regex = /^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/;
    return regex.test(String(alphaNumericSpecial));
}

export const alphaNumericSpaceSpecialValidation = (alphaNumericSpaceSpecial) => {
    const regex = /^[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ]*$/;
    return regex.test(String(alphaNumericSpaceSpecial));
}