import React from 'react'
import './ServiceCard.css'

export const ServiceCard = ({ img, title, description }) => {
  return (
    <>
      <div className='service-card-container'>
          <img src={img} alt='service' />
          <p>{title}</p>
          <span>{description}</span>
      </div>
    </>
  )
}
