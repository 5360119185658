import React from "react";
import "./PortalMessage.css";
import { ChatBody } from "./ChatBody/ChatBody";
import { Nav } from "./Nav/Nav";

export const PortalMessage = () => {
    return (
        <div className="__main">
            <Nav />
            <ChatBody />
        </div>
    );
};