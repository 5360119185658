import Cookies from "js-cookie";
import { toast } from "react-toastify";

export const handleLogout = async (navigate, location) => {
    await fetch(`${process.env.REACT_APP_ORIGIN}/logout`, {
        credentials: 'include',
        method: 'get',
        headers: { 'Content-Type': 'application/json' }
    });

    Cookies.remove('jwt');
    sessionStorage.removeItem('email')
    sessionStorage.removeItem('role')

    toast.success("Logout successfull");
    navigate('/admin/login', { replace: true, state: { from: location } });
}