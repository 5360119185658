import React from 'react'
import './UserProfile.css'
import { imagesArr } from '../../../assets';
import { useNavigate, useLocation } from 'react-router-dom';
import { handleLogout } from '../../../helper/handleLogout';
import { ROLES_LIST } from '../../../helper/rolesList';

export const UserProfile = ({ setSelected }) => {

    const role = sessionStorage.getItem("role");
    const [flag, setFlag] = React.useState(true);
    const navigate = useNavigate();
    const location = useLocation();

    const logout = async () => {
        await handleLogout(navigate, location);
    }

    return (
        <div className={`main__userprofile`}>
            <div className='profile__card'>
                <button className='toggleButton' onClick={() => setFlag(!flag)}>
                    <img src={imagesArr.Hamburger} alt="" />
                </button>
                <div className='cards'>
                    <div className='card__header' onClick={() => setSelected('Messages')}>
                        <img src={imagesArr.Email} className={``} alt="" />
                        <h4 className={flag ? 'hidden' : 'visible'}>Messages</h4>
                    </div>
                    {role === ROLES_LIST.admin &&
                        <div className='card__header' onClick={() => setSelected('Users')}>
                            <img src={imagesArr.Users} className={'w-7'} alt="" />
                            <h4 className={flag ? 'hidden' : 'visible'}>Users</h4>
                        </div>
                    }
                    {role === ROLES_LIST.admin &&
                        <div className='card__header' onClick={() => setSelected('Settings')}>
                            <img src={imagesArr.Settings} className={'w-7'} alt="" />
                            <h4 className={flag ? 'hidden' : 'visible'}>Settings</h4>
                        </div>
                    }
                </div>
            </div>
            <div className='profile__card'>
                <div className='cards'>
                    <div className='card__header' onClick={logout}>
                        <img src={imagesArr.Logout} className={'w-7'} alt="" />
                        <h4 className={flag ? 'hidden' : 'visible'}>Logout</h4>
                    </div>
                </div>
            </div>
        </div>
    )
}