import './Loader.css'

export const Loader = ({ visible }) => {
    return (
        !!visible && <>
            <div className="ch-loader-container">
                <div className="ch-loader-ring">
                    <div />
                    <div />
                    <div />
                    <div />
                </div>
            </div>
            <div className="ch-loader-background" />
        </>
    )
}
