import React from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify';
// import { imagesArr } from '../../assets'

export const Forget = () => {

    const [user, setUser] = useState('');

    const handleSubmit = async () => {

        const request = await fetch(`${process.env.REACT_APP_ORIGIN}/forgot`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ user }),
        });

        const response = await request.json();

        if (response.success) {
            toast.success("Reset Link Sent Successfully!")
        }
        else {
            toast.error("Error check logs")
        }
    }

    return (
        <div className='login-container'>
            <div className='login-form'>
                <div className='header-intro'>
                    <p>Don't worry we got you!</p>
                    <span>Enter your email to continue</span>
                </div>

                <div className='login-info'>
                    <input name="user" value={user} onChange={(e) => setUser(e.target.value)} type="email" id="user" placeholder='Email' />
                </div>

                <div className='button'>
                    <button onClick={handleSubmit}>Send Reset Link</button>
                </div>
                <div className='new-account'>
                    <h5>Already have an account?</h5>
                    <Link to={'../login'}>Login</Link>
                </div>
            </div>
            <div className='login-image image' />
        </div>
    )
}