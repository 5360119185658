// import Cookies from 'js-cookie'
import React from 'react'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';

export const Reset = () => {

    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [temp, setTemp] = useState('');
    const [c_pwd, setCpwd] = useState('');
    const navigate = useNavigate();
    const location = useLocation();

    const handleSubmit = async () => {

        if (c_pwd === pwd) {
            const request = await fetch(`${process.env.REACT_APP_ORIGIN}/reset`, {
                credentials: 'include',
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ user, pwd, temp })
            });

            // Password Mismatch
            if (request.status === 200) {
                toast.success("Reset Successfull!")
                navigate('/admin/login', { replace: true, state: { from: location } });
            }
            else {
                toast.error("Error accured, try again later")
            }
        }
        else {
            toast.warning("Confirm Password should be same")
        }
    }

    return (
        <div className='login-container'>
            <div className='login-form'>
                <div className='header-intro'>
                    <p>USER RESET!</p>
                    <span>Enter new Credentials</span>
                </div>

                <div className='login-info'>
                    <input name="user" value={user} onChange={(e) => setUser(e.target.value)} type="email" id="user" placeholder='Email' />
                    <input name="pwd" value={temp} onChange={(e) => setTemp(e.target.value)} type="password" id="temp_pwd" placeholder='Temporary Password' />
                    <input name="pwd" value={pwd} onChange={(e) => setPwd(e.target.value)} type="password" id="pwd" placeholder='New Password' />
                    <input name="pwd" value={c_pwd} onChange={(e) => setCpwd(e.target.value)} type="password" id="c_pwd" placeholder='Confirm New Password' />
                </div>
                <div className='remember-me'>
                    <input type="checkbox" name="" id="" />
                    <span>Remember me</span>
                </div>
                <div className='button'>
                    <button onClick={handleSubmit}>Reset</button>
                </div>
            </div>
            <div className='login-image image' />
        </div>
    )
}