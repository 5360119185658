import React from 'react'
import { imagesArr } from '../../assets'
import { Link, useLocation } from 'react-router-dom'
import "./Footer.css"
export const Footer = ({ userType }) => {
    const location = useLocation();
    const routes = {
        'Home': '/',
        'About': '/about',
        'Contact Us': '/contact',
        'Services': '/services',
        'Specialties': '/specialties',
    }

    return (
        <>
            {
                userType === 'c' ?
                <div className='footer-container'>
                    <div className='company__info'>
                        <div className='footer-column'>
                            <span>We are available 7 days a week.</span>
                            {
                                location.pathname !== '/contact' && <Link to="/contact">Get in Touch</Link>
                            }
                        </div>
                        <div className='footer-column'>
                            <h1>Our Center</h1>
                            <ul>
                                {Object.keys(routes).map((route, index) => (
                                    <li key={index} >
                                        <Link to={routes[route]}>{route}</Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className='footer-column'>
                            <h1>Find Us Here</h1>
                            <h2>7901 4TH ST N,<br></br>STE 10865,<br></br>ST. PETERSBURG,<br></br>FL 33702</h2>
                        </div>
                        <div className='footer-column'>
                            <h1>Contacts</h1>
                            <h2 className='mr-5 mb-5'><a href="mailto:info@7thskymb.com">info@7thskymb.com</a></h2>
                            <h2>+1 727 440 5915</h2>
                            <div className='social-container'>
                                <Link className='facebook'>
                                    <img src={imagesArr.Facebook} alt="" />
                                </Link>
                                <Link className='linkedin'>
                                    <img src={imagesArr.Linkedin} alt="" />
                                </Link>
                                <Link className='twitter'>
                                    <img src={imagesArr.Twitter} alt="" />
                                </Link>
                                <Link className='instagram'>
                                    <img src={imagesArr.Instagram} alt="" />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className='nobel__info'>
                        <span>
                            <Link to="#">7th Sky Medical Billing </Link>
                            © {new Date().getFullYear()} All Rights Reserved. Designed By
                            <a href="https://www.nobel10.com" rel="noopener noreferrer" target="_blank">  Nobel10 Technologies</a>
                        </span>
                    </div>
                </div>
                : null
            }

        </>
    )
}