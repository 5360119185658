import React from 'react'
import { Header, Footer, ClientMessage } from './components/componentIndex';
import AppRoutes from './routes/AppRoutes';

export const Global = () => {
    const [userType, setUserType] = React.useState('c');
    return (
        <>
            <ClientMessage />
            <div style={{ display: "flex", flexDirection: "column" }}>
                <Header userType={userType}/>
                <AppRoutes setUserType={setUserType} />
                <Footer userType={userType} />
            </div>
        </>
    )
}