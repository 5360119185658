import Cookies from 'js-cookie'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { decodeToken } from '../../helper/decodeToken'
import { ROLES_LIST } from '../../helper/rolesList'
import { Loader } from '../../components/componentIndex'
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify'
import { ToastContainer } from 'react-toastify'
import { emailValidation } from '../../helper/regex'

export const Login = () => {

    const [isVerified, setVerified] = useState(true);
    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const [isLoading, setLoading] = useState(false);

    const validateInputs=()=>{
        if (!emailValidation(user)){
            toast.error("Email format is not correct");
            return false;
        }else if(!user){
            toast.error("Username cannot be empty")
            return false;
        }else if (pwd.length <= 8 ){
            toast.error("Password length must be greater than 8")
            return false;
        }else{
            return true;
        }
    }

    const handleSubmit = async () => {
        if (validateInputs()) {
        
          if (isVerified) {
            setLoading(true);
            const request = await fetch(
              `${process.env.REACT_APP_ORIGIN}/auth`,
              {
                credentials: "include",
                method: "post",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ user, pwd }),
              }
            );

            const response = await request.json();
            // Invalid User 
            if (request.status === 401) {
                setLoading(false);
                toast.error("No account is associated with this email")
            }
            // Password Mismatch
            else if (request.status === 402) {
              setLoading(false);
              toast.error("Incorrect credentials")
            }
            // Not Verified
            else if (request.status === 403) {
              setVerified(false);
              setLoading(false);
              setPwd('');
            } else if (request.status === 200) {
              const accessToken = response?.accessToken;
              Cookies.set("jwt", accessToken);
              await decodeToken(accessToken);
              setLoading(false);
              toast.success("Login Succcessful");
              navigate("/admin/portal", {
                replace: true,
                state: { from: location },
              });
            } else {
              setLoading(false);
              toast.error("Some error accured, try again")
            }
          } else {
            // Call temp auth
            const tempRequest = await fetch(
              `${process.env.REACT_APP_ORIGIN}/tempAuth`,
              {
                credentials: "include",
                method: "post",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ user, pwd }),
              }
            );

            // validation request
            if (tempRequest.status === 201) {
              toast.error("Email validated")
              setLoading(false);
              window.location.reload();
            }
          }
        }

    }

    useEffect(() => {
        const checkState = async () => {
            const jwt = Cookies.get('jwt');
            if (jwt) {
                await decodeToken(jwt);
                if (sessionStorage.getItem('role')) {
                    if (sessionStorage.getItem('role') === ROLES_LIST.admin || sessionStorage.getItem('role') === ROLES_LIST.subAdmin) {
                        navigate('/admin/portal', { state: { from: location }, replace: true });
                    }
                    else {
                        navigate('/admin/login', { state: { from: location }, replace: true });
                    }
                }
            }
        }
        checkState();
    }, [ navigate])

    return (
        <>
            {!isVerified &&
                <div className="py-12 bg-gray-700 transition duration-150 ease-in-out z-10 absolute top-0 right-0 bottom-0 left-0">
                    <div className="container mx-auto w-11/12 md:w-2/3 max-w-lg">
                        <div className="relative py-8 px-5 md:px-10 bg-white shadow-md rounded border border-gray-400">

                            <p className="text-gray-800 font-lg font-bold leading-tight mb-4">
                                Your email is not verified, please check your email for a password</p>
                            <br></br>
                            <label for="name" className="text-gray-800 text-sm font-bold leading-tight">OTP</label>
                            <input id="name" type="password" name="otp" onChange={(e) => setPwd(e.target.value)} className="mb-5 mt-2 text-black focus:outline-none focus:border focus:border-indigo-700 font-normal w-full h-10 flex items-center pl-3 text-sm border-gray-300 rounded border" placeholder="What is it?" />


                            <div className="flex items-center justify-start w-full">
                                <button onClick={handleSubmit} className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
                                    Submit
                                </button>
                                <button onClick={() => setVerified(true)} className="bg-transparent ml-3 hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">
                                    Cancel
                                </button>
                            </div>
                            <button onClick={() => setVerified(true)} className="cursor-pointer absolute top-0 right-0 mt-4 mr-5 text-gray-400 hover:text-gray-600 transition duration-150 ease-in-out rounded focus:ring-2 focus:outline-none focus:ring-gray-600" aria-label="close modal">
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="20" height="20" viewBox="0 0 24 24" strokeWidth="2.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" />
                                    <line x1="18" y1="6" x2="6" y2="18" />
                                    <line x1="6" y1="6" x2="18" y2="18" />
                                </svg>
                            </button>
                            <ToastContainer/>
                        </div>
                    </div>
                    
                </div>
            }
            <Loader visible={isLoading} />
            {isVerified &&
                <div className='login-container'>
                    <div className='login-form'>
                        <div className='header-intro'>
                            <p>Welcome Back</p>
                            <span>Sign in to continue</span>
                        </div>

                        <div className='login-info'>
                            <input name="user" value={user} onChange={(e) => setUser(e.target.value)} type="email" id="user" placeholder='Email' />
                            <input name="pwd" value={pwd} onChange={(e) => setPwd(e.target.value)} type="password" id="pwd" placeholder='Password' />
                        </div>
                        <div className='remember-me'>
                            <input type="checkbox" name="" id="rem_me" />
                            <label htmlFor='rem_me'>Remember me</label>
                        </div>
                        <div className='button'>
                            <button onClick={handleSubmit}>Sign In</button>
                            <Link to={'../forget'}>Forgot Password?</Link>
                        </div>

                    </div>
                    <div className='login-image image' />
                    <ToastContainer/>
                </div>
            }
        </>
    )
}