import React from 'react'
import './ClientMessage.css'
import { Header } from './Header/Header';
import { Message } from './Message/Message';
import { BodyContent } from './Body/BodyContent';
import { imagesArr } from '../../assets'
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';


export const ClientMessage = () => {
  const location = useLocation();
  const [text, setText] = React.useState({
    msg: '',
    sentByClient: false,
    ack: false
  });
  const [flag, setFlag] = React.useState(false)
  const [isVisible, setVisible] = React.useState(true)
  const [active, setActive] = React.useState(false) // set to false on socket disconnect
  const [isNew, setisNew] = React.useState(Cookies.get('convoId') ? false : true);

  const style = {
    wrapper: `flex flex-col w-72 bg-slate-100 rounded-md h-96 ${isNew && "justify-between gap-5"}`,
    minimize: 'flex flex-col h-12 gap-5 rounded-md justify-between',
  }
  

  useEffect(() => {
    if (active) {
      setisNew(false);
    }
  }, [active])

  useEffect(() => {
    if (location.pathname === '/admin/login' || location.pathname === '/admin/reset' || location.pathname === '/admin/forget' || location.pathname === '/admin/portal') {
      setVisible(false);
    }
    else {
      setVisible(true);
    }
  }, [location])


  return isVisible && (
    <div className='message__bottom-right'>
      {
        flag ?
          <div className={style.wrapper}>
            <Header setFlag={setFlag} />
            {!isNew &&
              <>
                <BodyContent text={text} setText={setText} />
                <hr className='mb-2 mt-2 thick' />
                <Message setText={setText} setActive={setActive} />
              </>
            }
            {isNew &&
              <div>
                <p className='text-center text-gray-500 text-xs'>Kindly enter your email, so we can start the conversation</p>
                <hr className='mb-2 mt-2 thick' />
                <Message setActive={setActive} />
              </div>
            }
          </div> :
          <div className={style.minimize}>
            <button onClick={() => setFlag(true)}>
              <img src={imagesArr.Message} alt="" />
            </button>
          </div>
      }
    </div>
  )
}