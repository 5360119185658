import React from 'react'
import './ChatBody.css'
import { ChatList } from "../ChatList/ChatList"
import { ChatContent } from "../ChatContent/ChatContent"
import { UserProfile } from '../UserProfile/UserProfile'
import { useEffect, useState, useRef } from 'react'
import { io } from 'socket.io-client'
import { UserContent } from '../UserContent/UserContent'
import { SettingContent } from '../SettingContent/SettingContent'
import Cookies from 'js-cookie'

export const ChatBody = () => {

    const auth = Cookies.get('jwt') ? Cookies.get('jwt') : null;
    const [clients, setClients] = useState([]);
    const socket = useRef(null);
    const [selectedClient, setSelectedClient] = useState(null);
    const [newMessage, setNewMessage] = useState(null);
    const [ack, setAck] = useState(undefined)
    const [selectedSetting, setSelectedSetting] = useState('Messages')

    const getConversations = async (activeClients) => {
        const response = await fetch(`${process.env.REACT_APP_ORIGIN}/conversations`, {
            method: 'get',
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${auth}` }
        });

        const data = await response.json();

        let activefiltered = data.map((value) => {
            let online = activeClients?.find(t => t.convoId === value._id) === undefined ? false : true
            return { convoId: value._id, email: value.email, unread_count: value.unread_count, isOnline: online }
        })

        setClients(activefiltered);
    }

    useEffect(() => {
        getConversations();
    }, [newMessage])

    useEffect(() => {
        if (selectedClient) {
            let temp = clients.map((value) => {
                if (value.convoId === selectedClient.convoId) {
                    return { ...value, unread_count: 0 }
                }
                return value
            })
            setClients(temp)
        }
    }, [selectedClient])

    useEffect(() => {

        if (!socket.current) {

            socket.current = io.connect(process.env.REACT_APP_ORIGIN, {
                auth: {
                    'type': 'a'
                }
            })

            socket.current.on("activeUsers", (activeClients) => {
                getConversations(activeClients);
            });

            socket.current.on("adminResponse", (data) => {
                setAck(data)
            });

            socket.current.on("newMessage", (msg) => {
                setNewMessage(msg)
            });
        }
    })

    return (
        <div className='main__chatbody'>
            <div className='main__chatbody-body'>
                <UserProfile setSelected={setSelectedSetting} />
                {
                    selectedSetting === 'Messages' ? <ChatList clients={clients} selectedClient={selectedClient} setSelectedClient={setSelectedClient} setSelectedSetting={setSelectedSetting} />
                        : selectedSetting === 'Users' ? <UserContent usersList={[]} setSelectedSetting={setSelectedSetting} />
                            : selectedSetting === 'Settings' ? <SettingContent setSelectedSetting={setSelectedSetting} /> : null
                }
            </div>
            {
                selectedSetting === 'Messages' ? <ChatContent selected={selectedClient} setSelected={setSelectedClient} newMessage={newMessage} socket={socket} ack={ack} setAck={setAck} /> : null
            }
        </div>
    )
}