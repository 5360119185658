import Cookies from 'js-cookie';

export const decodeToken = async (auth) => {
    const response = await fetch(`${process.env.REACT_APP_ORIGIN}/role`, {
        method: 'get',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${auth}` }
    });

    if (response.status === 200) {
        const data = await response.json();
        sessionStorage.setItem('email', data.email)
        sessionStorage.setItem('role', data.roles)
    }
    else {
        Cookies.remove('jwt');
        sessionStorage.removeItem('email')
        sessionStorage.removeItem('role')
    }
}