/* eslint-disable no-restricted-globals */
import React from "react";
import { imagesArr } from "../../../assets";
import './ChatList.css'
import { ChatListItems } from "./ChatListItems";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import { handleLogout } from "../../../helper/handleLogout";
import { ROLES_LIST } from "../../../helper/rolesList";


export const ChatList = ({ clients, selectedClient, setSelectedClient, setSelectedSetting }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const role = sessionStorage.getItem('role');

    useEffect(() => {
    }, [selectedClient?.unread_count])

    return (
        <div className={`${selectedClient ? screen.width < 650 ? 'main__chatlist-hidden' : 'main__chatlist' : 'main__chatlist'}`}>
            <div className="chatlist__heading">
                <h2>Chats</h2>
                <Popup trigger={<button className="popup-button"><img src={imagesArr.Hamburger} alt="" /></button>} position="left top center">
                    <div>
                        <ul>
                            <li onClick={() => setSelectedSetting('Messages')}>Messages</li>
                            {
                                role === ROLES_LIST.admin && <li onClick={() => setSelectedSetting('Users')}>Users</li>
                            }
                            <li onClick={() => setSelectedSetting('Settings')}>Settings</li>
                            <li onClick={() => handleLogout(navigate, location)}>Logout</li>
                        </ul>
                    </div>
                </Popup>
            </div>
            <div className="chatlist__items">
                {clients.map((user, index) => (
                    <ChatListItems selectedClient={setSelectedClient} user={user} key={user.email} animationDelay={index + 1} active={user.isOnline ? "active" : ""} isOnline={user.isOnline ? "active" : ""} />
                ))}
            </div>
        </div>
    )
}