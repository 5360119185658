import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { imagesArr } from '../../assets'
import './Header.css'

export const Header = ({ userType }) => {

    const [flag, setFlag] = React.useState(false);
    const location = useLocation();
    const routes = {
        'Home': '/',
        'About': '/about',
        'Contact Us': '/contact',
        'Services': '/services',
        // 'Pricing': '/pricing',
        'Specialties': '/specialties',
        'Portal': '/admin/login',
    }

    return (
        <>
            {
                userType === 'c' ?
                    <header className={flag ? "mobile-header" : "header"}>
                        <div className={flag ? 'mobile__logo' : 'header__logo'}>
                            <Link to="/" onClick={() => setFlag(false)}>
                                <img src={imagesArr.Logo1} alt="" />
                            </Link>
                        </div>
                        <nav className={`${flag ? "mobile" : "header"}__nav`}>
                            <ul>
                                {Object.keys(routes).map((route, index) => (
                                    <li key={index}>
                                        <Link to={routes[route]} className={routes[route] === location.pathname && 'active-link'} onClick={() => setFlag(false)}>{route}</Link>
                                    </li>
                                ))}
                            </ul>
                            <button onClick={() => { setFlag(true) }}>
                                <img src={imagesArr.Hamburger} alt="Navbar" />
                            </button>
                        </nav>
                    </header>
                    : null
            }
        </>
    )
}