import React from 'react'
import { useState } from 'react';
import './SettingContent.css';
import { handleLogout } from '../../../helper/handleLogout';
import { useNavigate, useLocation } from 'react-router-dom';
import { imagesArr } from "../../../assets";
import Popup from "reactjs-popup";
import { toast, ToastContainer } from 'react-toastify';
import Cookies from 'js-cookie';

export const SettingContent = ({ setSelectedSetting }) => {

    const navigate = useNavigate();
    const location = useLocation();
    const auth = Cookies.get('jwt') ? Cookies.get('jwt') : null;
    const email = sessionStorage.getItem('email');
    const user = email;
    const [pwd, setPwd] = useState('');
    const [confirm, setConfirm] = useState('');

    const validateInputs=()=>{
        if (pwd.length <= 8){
            toast.error("Password length must be greater than 8")
            return false;
        }else if(pwd !== confirm){
            toast.error("Password must match")
            return false;
        }
        return true;
    }

    const handleSubmit = async () => {
        if (validateInputs()) {

            const request = await fetch(`${process.env.REACT_APP_ORIGIN}/update`, {
                credentials: 'include',
                method: 'post',
                headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${auth}` },
                body: JSON.stringify({ user, pwd })
            });

            // Password Mismatch
            if (request.status === 200) {
                toast.success("Reset Successfull!")
                setPwd('')
                setConfirm('')
            }
            else {
                toast.error("Error accured, try again");
            }
        }
    }

    return (
        <div className='setting-container'>
            <div className='header-container'>
                <h1>Profile</h1>
                <Popup trigger={<button className="popup-button"><img src={imagesArr.Hamburger} alt="" /></button>} position="left top center">
                    <div>
                        <ul>
                            <li onClick={() => setSelectedSetting('Messages')}>Messages</li>
                            <li onClick={() => setSelectedSetting('Users')}>Users</li>
                            <li onClick={() => setSelectedSetting('Settings')}>Settings</li>
                            <li onClick={() => handleLogout(navigate, location)}>Logout</li>
                        </ul>
                    </div>
                </Popup>
            </div>
            <h3>Update Password</h3>
            <div className='profile-info'>
                <div className='info-line'>
                    <input value={pwd} onChange={(e) => { setPwd(e.target.value) }} id='change-password' type="password" placeholder='Change Password' />
                    <div className='visibility'>
                        <input id='chkbox1' type="checkbox" />
                        <label htmlFor="chkbox1">Show Password</label>
                    </div>
                </div>
                <h3>Confirm New Password</h3>
                <div className='info-line'>
                    <input value={confirm} onChange={(e) => { setConfirm(e.target.value) }} id='confirm-password' type="password" placeholder='Confirm New Password' />
                </div>
                <button onClick={handleSubmit}>Update</button>
            </div>

            <div className='profile-info'>
                <h1>Chat Backup</h1>
                <div className='note'>
                    <span>{process.env.REACT_APP_CHAT_EXPIRY} Days  </span>
                    <p> : The duration for which chats will be available, after that duration chat will be deleted. </p>
                </div>
                {/* <select name="" id="">
                    {
                        [...Array(30)].map((_, i) => {
                            return <option value={i + 1}>{i + 1}</option>
                        })
                    }
                </select> */}

                {/* <button>Set Duration</button> */}
            </div>
            <ToastContainer/>
        </div>
    )
}