import React from 'react'
import { HomeCard, HoverImage, TestimonialCard } from '../../components/componentIndex'
import { imagesArr } from '../../assets'
import { Link } from 'react-router-dom'

export const Home = () => {
  const teamDescription = 'Communicating and working across the board and accomplishing.'
  const serviceDescription = 'Timely efficient, Skilled, Always dedicated'
  const globalWorkdescription = 'Data shared by clients are highly secured and encrypted'
  const aboutUsdecription = 'A Fast-growing medical billing and Revenue Cycle Management company.\nWith strong technology division, brings the perfect blend of services for our clients.\nOur team possesses an in-depth understanding of medical billing and coding.\nWe create customized solutions tailored for our customers’ unique needs which defines quality healthcare medical billing solutions'
  const testimonials = [
    {
      name: 'Ahmad Ali',
      designation: 'Lahore',
      description: "I highly recommend 7th Sky and their work! I have had the pleasure of boosting my revenue over a number of my patient cases. We've communicated regularly with 7th Sky in writing, on video and had meetings where they showed an exceptional proof of concept."
    },
    {
      name: 'Nabeel Ahmed',
      designation: 'Lahore',
      description: "7th Sky MBS is an absolute pleasure to work with, is such a dedicated amount of work in managing insurances and billings. Knowledgeable and speaks of their professionalism."
    },
    {
      name: 'Rizwan Khan',
      designation: 'Lahore',
      description: "What most caught my attention was their quality and accuracy of work. With its focus directed to the target, 7th Sky Medical Billing and their employees have proven to be intelligent professionals who have knowledge of this industry, without forgetting they are really fast, helpful (a lot) and polite. And, I feel a privileged to be knowing and availing their actionable quality in delivery of service and profits."
    }
  ]

  const why7Sky = [
    {
      title: '10 Years of Experience',
      description: 'Same professional management team for last 10 years',
    },
    {
      title: 'A/R Collection Cycle',
      description: 'A/R collection cycle substantially better than the national average',
    },
    {
      title: 'Quality of Work',
      description: 'Consistently performs at levels superior to industry standards ',
    },
    {
      title: 'Facilities',
      description: 'All specialties and standalone facilities ',
    },
    {
      title: 'Billing Experience',
      description: 'Billing, A/R Management and credentialing experience across all payers',
    },
    {
      title: 'Experienced Staff',
      description: 'Experienced Staff that is continuously educated on latest reimbursement changes. Team expertise ranging from 5 years to 10 years',
    }
  ]

  return (
    <>
      <div className='home-container'>
        <section id='home'>
          <div className='home-container__bg'>
            <div className='home-container__bg__content'>
              <p>7th Sky Medical Billing</p>
              <h3>Best Billing Service</h3>

              <Link to={'/about'}>
                <button>Read More</button>
              </Link>
              <div className='home-container__bg__card'>
                <HomeCard image={imagesArr.Users} title={'Protect'} subtitle={'Qualified Team'} description={teamDescription} />
                <HomeCard image={imagesArr.ShieldHalved} title={'Help'} subtitle={'Quality Service'} description={serviceDescription} />
                <HomeCard image={imagesArr.Globe} title={'World'} subtitle={'Data Security'} description={globalWorkdescription} />
              </div>
            </div>
          </div>
        </section>
        <section id='about'>
          <div className='about-container'>
            <div className='about-container-left'>
              <p>About Us</p>
              <span>Quality and Excellence in Medical Billing.</span>
            </div>
            <div className='about-container-right'>
              <p>{aboutUsdecription}</p>
              {/* <p>{aboutUsDescriptionSecond}</p> */}
              <Link to={'/about'}>
                <button>Read More</button>
              </Link>
            </div>
          </div>
        </section>
        <section id='services'>
          <div className='services-container'>
            <div className='services-container-left'>
              <p>Extraordinary Services</p>
              <span>Why you should choose 7th Sky Medical Billing</span>
              {
                why7Sky.map((item, index) => (
                  <div className='services' key={index}>
                    <HoverImage image={imagesArr.Eye} />
                    <div className='service'>
                      <p>{item.title}</p>
                      <span>{item.description}</span>
                    </div>
                  </div>
                ))
              }
            </div>
            <div className='services-container-right'>
              <img src={imagesArr.HomeIcon} alt="Service" />
            </div>
          </div>
        </section>
        <section id='testimonials'>
          <div className='testimonials-container'>
            <p>Happy Clients</p>
            <span>What Our Clients Say</span>
            <div className='testimonials'>
              {testimonials.map((testimonial, index) => (
                <TestimonialCard key={index} name={testimonial.name} location={testimonial.designation} review={testimonial.description} />
              ))}
            </div>
          </div>
        </section>
      </div>
    </>
  )
}