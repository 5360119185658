import Cookies from 'js-cookie';
import React, { useRef, useState } from 'react'
import { useEffect } from 'react';
import { ChatItem } from '../../PortalMessage/ChatContent/ChatItem'
import './BodyContent.css'

export const BodyContent = ({ text, setText }) => {
    const messagesEndRef = useRef(null);
    const [isFetching, setFetching] = useState(true);
    const [chatList, setChatList] = useState([
        {
            message: "Hello, How can I help you?",
            sentByClient: false,
            ack: true
        },
    ]);

    useEffect(() => {

        if (Cookies.get('convoId') && Cookies.get('jwt') && isFetching) {

            const getChats = async () => {

                const response = await fetch(`${process.env.REACT_APP_ORIGIN}/chats/${Cookies.get('convoId')}`, {
                    method: 'get',
                    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${Cookies.get('jwt')}` }
                });

                const data = await response.json();
                let filtered = data.map((value) => {
                    return { message: value.message, sentByClient: value.sentByClient, ack: true }
                })

                setChatList([{
                    message: "Hello, How can I help you?",
                    sentByClient: false,
                    ack: true
                }, ...filtered]);

                setFetching(false);

            }

            getChats();
        }
    }, [isFetching])

    useEffect(() => {

        if (text.msg !== '') {
            setChatList([...chatList, { message: text.msg, sentByClient: text.sentByClient, ack: text.ack }])
            setText({
                msg: '',
                sentByClient: false,
                ack: false
            })
        }

    }, [text, chatList, setText]);

    useEffect(() => {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" })
    }, [chatList])

    return (
        <div className="main__bodychatcontent">
            <div className="content__body">
                <div className="chat__items">
                    {
                        chatList.map((item, index) => {
                            return <ChatItem acknowledgment={item.ack} animationDelay={index + 2} key={item.index} user={item.sentByClient ? "me" : "other"} msg={item.message} />
                        })
                    }
                    <div ref={messagesEndRef} />
                </div>
            </div>
        </div>
    )
}