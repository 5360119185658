import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { MultipleTitlePointCard } from '../../components/ServiceCard/MultipleTitlePointCard'
import { PointCard } from '../../components/ServiceCard/PointCard'
import { TitlePointCard } from '../../components/ServiceCard/TitlePointCard'
import { TitlePointHighlightCard } from '../../components/ServiceCard/TitlePointHighlightCard'

/*
  a -> Points[title, descriptions]
  b -> Points[descriptions]
  c -> Points[title[], descriptions[]]
  d -> Points[title, descriptions, ?highlights[], ?extr]
*/

export const ServiceDetail = ({ ServiceName, ServiceDescription, Points, Type }) => {
  const location = useLocation()
  const navigate = useNavigate()
  React.useEffect(() => {
    if (!ServiceName || !ServiceDescription || !Points || !Type) {
      navigate('/services', { state: { from: location.pathname } })
    }
  }, [Points, ServiceDescription, ServiceName, Type, navigate, location.pathname])
  return (
    <>
      <div className='service-detail'>
        <h2>{ServiceName}</h2>
        <span>{ServiceDescription}</span>
        <h3>Why Choose our {ServiceName}?</h3>
        {
          Points.map((point, index) => (
            <div>
              {
                Type === 'a' && <TitlePointCard info={point.info} image={point.image} direction={index % 2 === 0 ? 'left' : 'right'} />
              }
              {
                Type === 'b' && <PointCard point={point.descriptions} image={point.image} direction={index % 2 === 0 ? 'left' : 'right'} />
              }
              {
                Type === 'c' && <MultipleTitlePointCard descriptions={point.descriptions} image={point.image} title={point.title} direction={index % 2 === 0 ? 'left' : 'right'} />
              }
              {
                Type === 'd' && <TitlePointHighlightCard title={point.title} descriptions={point.descriptions} image={point.image} highlights={point.highlights} extra={point.extra} direction={index % 2 === 0 ? 'left' : 'right'} />
              }
            </div>
          ))
        }
      </div>
    </>
  )
}