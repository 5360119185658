import React from "react";
import { imagesArr } from "../../assets";
import '../../styles/Contact_us.css'
import { useState } from "react";
import { toast, ToastContainer } from "react-toastify";

export const ContactUs = () => {

    const [info, setInfo] = useState({
        name: "",
        age: "",
        email: "",
        specialty: "",
        state: "",
        description: "",
    });

    const checkFieldValidationRegex = () => {
        let nameRegex = /^[a-zA-Z ]{2,30}$/;
        let ageRegex = /^[0-9]{1,2}$/;
        let emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        let specialtyRegex = /^[a-zA-Z0-9 ]{2,}$/;
        let addressRegex = /^[a-zA-Z0-9\s,.'-]{3,}$/;
        let descriptionRegex = /^[a-zA-Z0-9 ]{2,}$/;

        return (nameRegex.test(info.name) && ageRegex.test(info.age) && emailRegex.test(info.email) && specialtyRegex.test(info.specialty) && addressRegex.test(info.address) && descriptionRegex.test(info.description));
    }

    const resetFields = () => {
        setInfo({
            name: "",
            age: "",
            email: "",
            contact: "",
            address: "",
            description: "",
        });
    }

    const statesUs = [
        "Alabama",
        "Alaska",
        "Arizona",
        "Arkansas",
        "California",
        "Colorado",
        "Connecticut",
        "Delaware",
        "Florida",
        "Georgia",
        "Hawaii",
        "Idaho",
        "Illinois",
        "Indiana",
        "Iowa",
        "Kansas",
        "Kentucky",
        "Louisiana",
        "Maine",
        "Maryland",
        "Massachusetts",
        "Michigan",
        "Minnesota",
        "Mississippi",
        "Missouri",
        "Montana",
        "Nebraska",
        "Nevada",
        "New Hampshire",
        "New Jersey",
        "New Mexico",
        "New York",
        "North Carolina",
        "North Dakota",
        "Ohio",
        "Oklahoma",
        "Oregon",
        "Pennsylvania",
        "Rhode Island",
        "South Carolina",
        "South Dakota",
        "Tennessee",
        "Texas",
        "Utah",
        "Vermont",
        "Virginia",
        "Washington",
        "West Virginia",
        "Wisconsin",
        "Wyoming",
    ]

    const sendEmail = async (e) => {
        e.preventDefault()
        console.log(info.state)
        if (checkFieldValidationRegex()) {
            const res = await fetch(`${process.env.REACT_APP_ORIGIN}/email`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                }, body: JSON.stringify(info),
            })
            const data = await res.json();
            if (data.status === 401 || !data) {
            } else {
                toast.success("Email Sent Successfully")
                resetFields();
            }
        }
        else {
            let nameRegex = /^[a-zA-Z ]{2,30}$/;
            let ageRegex = /^[0-9]{1,2}$/;
            let emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
            let specialtyRegex = /^[a-zA-Z0-9 ]{2,}$/;
            let stateRegex = /^[a-zA-Z0-9\s,.'-]{3,}$/;
            let descriptionRegex = /^[a-zA-Z0-9 ]{2,}$/;

            let array = "";

            if (!nameRegex.test(info.name)) {
                array += "Name,";
            }
            if (!ageRegex.test(info.age)) {
                array += "Age,";
            }
            if (!emailRegex.test(info.email)) {
                array += "Email,";
            }
            if (!specialtyRegex.test(info.specialty)) {
                array += "Specialty,";
            }
            if (!stateRegex.test(info.state)) {
                array += "Address,";
            }
            if (!descriptionRegex.test(info.description)) {
                array += "Description";
            }
            array = array.split(",")


            let string = "Please enter valid "
            if (array.length >= 2) {
                array.forEach((item, index) => {
                    if (index === array.length - 1) {
                        string += "and " + item + ".  "
                    }
                    else {
                        string += item + ", "
                    }
                })
            }
            else {
                string += array[0] + ".  "
            }

            // remove last 2 characters
            string = string.substring(0, string.length - 2);
            toast.warn(string)

        }
    }

    const handleInputs = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        setInfo({ ...info, [name]: value })
    }

    return (
        <div className="contact-form">
            <div className="card">
                <div className="image">
                    <img className="img-size" src={imagesArr.Stethoscope} alt="Stethoscope" />
                    <span>We have a dedicated support center for all of your support needs. We usually get back to you within 12-24 hours.
                        Your Privacy is our main priority. All of your queries will be hidden to others.
                    </span>
                </div>
                <div className="card-color">
                    <h1 className="help-us">How can we help?</h1>
                    <div className="container">
                        <div className="input-form">
                            <div className="wrapper">
                                <div className="Input">
                                    <input type="text" required name="name" value={info.name} className="Input-text" onChange={handleInputs} placeholder="Full Name" />
                                </div>
                            </div>

                            <div className="wrapper">
                                <div className="Input">
                                    <input type="number" required name="age" value={info.age} className="Input-text" onChange={handleInputs} placeholder="Age" />
                                </div>
                            </div>

                            <div className="wrapper">
                                <div className="Input">
                                    <input type="text" required name="email" value={info.email} className="Input-text" onChange={handleInputs} placeholder="Email" />
                                </div>
                            </div>

                            <div className="wrapper">
                                <div className="Input">
                                    <input type="text" required name="specialty" value={info.specialty} className="Input-text" onChange={handleInputs} placeholder="Specialty" />
                                </div>
                            </div>

                            <div className="wrapper">
                                <div className="Input">
                                    {/* <input type="text" required name="state" value={info.state} className="Input-text" onChange={handleInputs} placeholder="State" /> */}
                                    <select name="state" value={info.state} onChange={handleInputs} placeholder="State" required>
                                        <option value="" disabled>State</option>
                                        {statesUs.map((state, index) => {
                                            return (
                                                <option key={index} value={state}>{state}</option>
                                            )
                                        })}
                                    </select>
                                </div>
                            </div>

                            <div className="wrapper">
                                <div className="Input">
                                    <input type="text" required name="description" value={info.description} className="Input-desc" onChange={handleInputs} placeholder="Description" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="butt">
                        <button type="submit" className='submit-button' onClick={sendEmail}>Submit</button>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}