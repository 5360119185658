import React from 'react'
import './Nav.css'

export const Nav = () => {
    return (
        <div className='nav'>
            <div className='nav__blocks' />
            <div className='nav__blocks' />
        </div>
    )
}