import React from 'react'
import { PortalMessage } from '../../components/componentIndex';

export const Portal = () => {
    return (
        <>
            <div>
                <PortalMessage />
            </div>
        </>
    )
};
