/* eslint-disable no-restricted-globals */
import React, { useRef, useState } from "react";
import "./ChatContent.css";
import { Avatar } from "../ChatList/Avatar";
import { ChatItem } from "./ChatItem";
import { imagesArr } from "../../../assets";
import { useEffect } from "react";
import Cookies from "js-cookie";

export const ChatContent = ({ selected, setSelected, newMessage, socket, ack, setAck }) => {

    const auth = Cookies.get('jwt') ? Cookies.get('jwt') : null;
    const messagesEndRef = useRef(null);
    const [msg, setMsg] = useState("");
    const [chatList, setChatList] = useState([]);

    // admin recieve message from client
    useEffect(() => {
        if (newMessage) {

            if (selected?.convoId === newMessage.convoId) {
                setChatList([...chatList, { message: newMessage.msg, sentByClient: newMessage.type, ack: true }]);
            }
            else {

                // increase unread count....
                const updateMessageCount = async () => {
                    await fetch(`${process.env.REACT_APP_ORIGIN}/conversations/${newMessage.convoId}`, {
                        method: 'PATCH',
                        headers: {
                            'Content-Type': 'application/json', Authorization: `Bearer ${auth}`
                        },
                    });
                }

                updateMessageCount();

            }
        }

    }, [newMessage])

    useEffect(() => {
        if (selected) {
            setChatList([]);
            const getChats = async () => {

                const response = await fetch(`${process.env.REACT_APP_ORIGIN}/chats/${selected.convoId}`, {
                    method: 'get',
                    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${auth}` }
                });

                const data = await response.json();
                let filtered = data.map((value) => {
                    return { message: value.message, sentByClient: value.sentByClient, ack: true }
                })
                setChatList(filtered);
            }

            getChats().then(async () => {

                // reset unread count
                await fetch(`${process.env.REACT_APP_ORIGIN}/conversations/${selected.convoId}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json', Authorization: `Bearer ${auth}`
                    },
                });

                setSelected({
                    ...selected,
                    unread_count: 0
                })
            });
        }

    }, [selected?.convoId])


    // admin sent message
    useEffect(() => {

        if (ack !== undefined) {
            setChatList([...chatList, { message: msg, sentByClient: false, ack: ack }]);
            setAck(undefined);
            setMsg('');
        }

    }, [ack])

    useEffect(() => {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    }, [chatList])

    const handleMsg = (e) => {
        setMsg(e.target.value);
    }

    const sendMsg = () => {
        if (msg !== "" && msg !== " ") {
            socket.current.emit("adminMessage", { convoId: selected.convoId, msg: msg, type: false });
        }
    }

    return (
        <div className={`${selected ? screen.width > 650 ? 'main__chatcontent' : 'main__chatcontent-mobile' : screen.width > 650 ? 'main__chatcontent' : 'main__chatcontent-hidden'}`}>
            <div className="content__header">
                <div className="blocks">
                    <div className="current-chattin-user">
                        <button className={"back-to-list"} onClick={() => setSelected(null)}>
                            <img src={imagesArr.AngleLeft} alt="" />
                        </button>
                        <Avatar isOnline={'active'} image={imagesArr.UserIcon} />
                        <p>{selected?.email}</p>
                    </div>
                </div>
                {/* <div className="blocks">
                    <div className="settings">
                        <button className="btn-nobg">
                            <img src={imagesArr.Settings} alt="" />
                        </button>
                    </div>
                </div> */}
            </div>

            <div className="content__body">
                <div className="chat__items">
                    {
                        chatList.map((item, index) => {
                            return <ChatItem acknowledgment={item.ack} animationDelay={index + 2} key={index} user={item.sentByClient ? "other" : "me"} msg={item.message} />
                        })
                    }
                    <div ref={messagesEndRef} />
                </div>
            </div>

            <div className="content__footer">
                <div className="sendNewMessage">
                    <input type="text" placeholder="Type a Message Here" onChange={handleMsg} value={msg} />
                    <button onClick={sendMsg} className="btnSendMsg" id="sendMsgBtn">
                        <img src={imagesArr.PaperPlane} alt="" />
                    </button>
                </div>
            </div>
        </div>
    )
}