import React from 'react'
import { imagesArr } from "../../assets"

export const PointCard = ({ point, image, direction='left' }) => {
  return (
    <div className={`point-card-container ${direction}`}>
      <div className='row'>
        {
          point.map((point, index) => (
            <div className='column'>
              <img className='check' src={imagesArr.Check} alt=''/>
              <h3>{point.description}</h3>
            </div>
          ))
        }
      </div>
      <img src={image} alt="" />
    </div>
  )
}