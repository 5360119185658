import { useLocation, Navigate, Outlet } from "react-router-dom";
import { ROLES_LIST } from "../helper/rolesList";
import Cookies from "js-cookie";

export const PrivateRoutes = () => {
    const location = useLocation();
    let auth = Cookies.get("jwt");
    let roles = sessionStorage.getItem("role");
    return (
        auth ?
            (roles === ROLES_LIST.admin || roles === ROLES_LIST.subAdmin)
                ? <Outlet />
                : <Navigate to="/admin/login" state={{ from: location }} replace />
            : <Navigate to="/admin/login" state={{ from: location }} replace />
    );
}
