import React from 'react'
import { Link } from 'react-router-dom'
import { imagesArr } from '../../assets'
import { TeamCard } from '../../components/componentIndex'

export const About = () => {
    const aboutUslineDescription='Quality and Excellence in Medical Billing'
    const aboutUsDescription='As a HIPAA compliant, we offer pricing plans to suit your needs, so you can find the one that best meets your budget. Achieve the highest recovery on contractually due amounts from payers with our dedicated staff. Unrivaled commitment to compliance and superior customer service.'
    const aboutUsDescriptionSecond='To make our work transparent and time-saving, we carefully examine and scrutinize every term in the documentation processing and claims before and during processing to ensure no discrepancies are left out.'
    const innovationDescription='In an interconnected world 7th Sky believes in constantly evolving and expanding our services.'
    const compasisonDescription='Personalized care and evaluation for claims and inclusive submission with structured checks and due diligence.'
    const integrityDescription='There is no requirement for software to install. Claims and submissions are checked before and during processing. Ensuring a hassle-free and financially secure medical billing services.'
    
    const advantagesDescription="We understand and focus on making our client's work fast, reliable, and cost-effective. Which is what has given us a competitive edge."
    const healingExperienceDescription="When it comes to Medicaid, Medicare and many other insurers. Specialists at 7th Sky make sure to process fast and affordably with careful checks for even the smallest discrepancies before and during processing, using a structured and responsive approach. This makes our service financially affordable and reliable to medical professionals"
    return (
        <>
            <div className='about-container'>
                <div className='about'>
                    <div className='about-left'>
                        <span>About Us</span>
                        <p>{aboutUslineDescription}</p>
                    </div>
                    <div className='about-right'>
                        <p>{aboutUsDescription}</p>
                        <p>{aboutUsDescriptionSecond}</p>
                        <Link to={'/services'}>
                            <button>Read More</button>
                        </Link>
                    </div>
                </div>
                <div className='motivation'>
                    <span>Motivation is Easy</span>
                    <p>Our Core Values</p>
                    <div className='motivation-content'>
                        <div className='content'>
                            <img src={imagesArr.LightBulb} alt=''/>
                            <span>Innovation</span>
                            <p>{innovationDescription}</p>
                        </div>
                        <div className='content'>
                            <img src={imagesArr.Handshake} alt=''/>
                            <span>Compassion</span>
                            <p>{compasisonDescription}</p>
                        </div>
                        <div className='content'>
                            <img src={imagesArr.Podcast} alt=''/>
                            <span>Integrity</span>
                            <p>{integrityDescription}</p>
                        </div>
                    </div>
                </div>
                <div className='what-we-offer'>
                    <div className='what-we-offer-left'>
                        <span>What We Offer</span>
                        <p>Our Advantages</p>
                        <h3>{advantagesDescription}</h3>
                        <div className='advantage'>
                            <img src={imagesArr.Check} alt=''/>
                            <span>Same professional management team for last 10 years</span>
                        </div>
                        <div className='advantage'>
                            <img src={imagesArr.Check} alt=''/>
                            <span>A/R collection cycle substantially better than the national average</span>
                        </div>
                        <div className='advantage'>
                            <img src={imagesArr.Check} alt=''/>
                            <span>Consistently performs at levels superior to industry standards</span>
                        </div>
                        <div className='advantage'>
                            <img src={imagesArr.Check} alt=''/>
                            <span>All specialties and standalone facilities</span>
                        </div>
                        <div className='advantage'>
                            <img src={imagesArr.Check} alt=''/>
                            <span>Billing, A/R Management and credentialing experience across all payers</span>
                        </div>
                        <div className='advantage'>
                            <img src={imagesArr.Check} alt=''/>
                            <span>Experienced Staff that is continuously educated on latest reimbursement changes. Team expertise ranging from 5 years to 10 years</span>
                        </div>
                    </div>
                    <div className='what-we-offer-right'>
                        <img src={imagesArr.AboutAdvantages} alt="" />
                    </div>
                </div>
                <div className='experience'>
                    <span>Here &amp; Now, Every Day</span>
                    <p>Healing Experience -  For Everyone All The Time</p>
                    <div className='experience-content'>
                        <p>{healingExperienceDescription}</p>
                    </div>
                    <Link to={'/contact'}>
                        <button>Get In Touch</button>
                    </Link>
                </div>
                <section id='team'>
                    <div className='team'>
                        <span>Leading Team</span>
                        <p>Meet Our Team</p>
                        <div className='team-content'>
                            <TeamCard image={imagesArr.Team1} name={'Jennifer Foster'} profession={'Associate Dentist'} />
                            <TeamCard image={imagesArr.Team2} name={'Dhony Abraham'} profession={'Orthodontist'} />
                            <TeamCard image={imagesArr.Team3} name={'Marko Dugonji'} profession={'Health Therapist'} />
                            <TeamCard image={imagesArr.Team4} name={'Rhoda Byrd'} profession={'Implantologist'} />
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}