import React from 'react'
import { Link } from 'react-router-dom'
import { imagesArr } from '../../assets'
import './Socials.css'

export const Socials = () => {
    return (
        <>
            <div className='social-container'>
                <Link className='facebook'>
                    <img src={imagesArr.Facebook} alt="" />
                </Link>
                <Link className='twitter'>
                    <img src={imagesArr.Twitter} alt="" />
                </Link>
                <Link className='instagram'>
                    <img src={imagesArr.Instagram} alt="" />
                </Link>
                <Link className='linkedin'>
                    <img src={imagesArr.Linkedin} alt="" />
                </Link>
            </div>
        </>
    )
}