import React from 'react'
import './UserContent.css'
import { useState } from 'react'
import { useEffect, useCallback } from 'react'
import Popup from 'reactjs-popup'
import { handleLogout } from '../../../helper/handleLogout'
import { useNavigate, useLocation } from 'react-router-dom'
import { imagesArr } from '../../../assets'
import Cookies from 'js-cookie'
import { toast, ToastContainer } from 'react-toastify'
import { emailValidation } from '../../../helper/regex'

export const UserContent = ({ setSelectedSetting }) => {

    const navigate = useNavigate();
    const location = useLocation();
    const auth = Cookies.get('jwt');
    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');


    // const [open, setOpen] = React.useState(false)
    const [userList, setUserList] = useState([])

    const validateInputs=()=>{
        if(!emailValidation(user)){
            toast.error("Email is not in correct format")
            return false;
        }
        else if (pwd.length <= 8){
            toast.error("Password length must be greater than 8")
            return false;
        }
        return true;
    }

    const fetchUsers = useCallback(async () => {

        const request = await fetch(`${process.env.REACT_APP_ORIGIN}/users`, {
            method: 'get',
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${auth}` }
        });

        const response = await request.json();

        setUserList(response);
    })

    const handleNewUser = async () => {

        if (validateInputs()) {
          const request = await fetch(
            `${process.env.REACT_APP_ORIGIN}/register`,
            {
              credentials: "include",
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${auth}`,
              },
              body: JSON.stringify({ user, pwd }),
            }
          );

          const response = await request.json();

          if (response.success) {
            toast.success("User Registered Successfully!");
          } else {
            toast.error("Error check logs");
          }
        }
    }

    const handleDelete = async (email) => {
        await fetch(`${process.env.REACT_APP_ORIGIN}/users`, {
            method: 'delete',
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${auth}` },
            body: JSON.stringify({ email: email })
        });

        fetchUsers();
    }

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers])

    return (
        <div className='user-content-container'>
            <div className='header-container'>
                <h3>Users List</h3>
                <Popup trigger={<button className="popup-button"><img src={imagesArr.Hamburger} alt="" /></button>} position="left top center">
                    <div>
                        <ul>
                            <li onClick={() => setSelectedSetting('Messages')}>Messages</li>
                            <li onClick={() => setSelectedSetting('Users')}>Users</li>
                            <li onClick={() => setSelectedSetting('Settings')}>Settings</li>
                            <li onClick={() => handleLogout(navigate, location)}>Logout</li>
                        </ul>
                    </div>
                </Popup>
            </div>
            <div className='add-user'>
                <input type='text' onChange={(e) => setUser(e.target.value)}
                    value={user} placeholder='Enter email' />
                <input onChange={(e) => setPwd(e.target.value)}
                    value={pwd} type='password' placeholder='Enter password' />
                <button onClick={handleNewUser}>Add User</button>
            </div>
            <div className='user-list'>
                <table>
                    <tbody>
                        <tr>
                            <th>Email</th>
                            <th>Actions</th>
                        </tr>
                        {
                            userList.map((user, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{user.email}</td>
                                        <td>
                                            <button onClick={() => handleDelete(user.email)}>Delete</button>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                <ToastContainer/>
            </div>
        </div>
    )
}