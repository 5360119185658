import React from 'react'
import { imagesArr } from '../../../assets'
import './Header.css'

export const Header = ({setFlag}) => {
    return (
        <div className='display'>
            <img src={imagesArr.UserIcon} alt="" />
            <div className='header-content'>
                <span>Chat With Us</span>
            </div>
            <button onClick={() => setFlag(false)}>
                <img src={imagesArr.Close} alt="" />
            </button>
        </div>
    )
}