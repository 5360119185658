import React from 'react'
import { imagesArr } from '../../assets'
import { ServiceCard } from '../../components/componentIndex'
import { Link } from "react-router-dom"

const changedServices = [
  {
    image: imagesArr.ChargesEntry,
    title: 'Medical Billing Services',
    description: 'Our Expert medical billers will make sure that you will be reimbursed at the maximum, and we will manage your account.',
    points: [
      {
        info: [
          {
            title: 'Affordable:',
            description: 'We provide cost-effective and easy medical billing services that suit the budget and help cut down the cost.',
          },
          {
            title: 'Certifications:',
            description: 'We are a certified company that adheres to HIPAA compliance',
          },
        ],
        image: imagesArr.MedicalBillingService.MBS1,
      },
      {
        info: [
          {
            title: 'Data Security:',
            description: 'We understand the importance of data security and hence prevent unauthorized access or modification',
          },
          {
            title: 'Quality Services:',
            description: 'We provide personalized services to meet quality standards. Our track record is impeccable and our services are absolutely tailor-made',
          },
        ],
        image: imagesArr.MedicalBillingService.MBS3,
      },
    ],
    type: 'a'
  },
  {
    image: imagesArr.Coding,
    title: 'Medical Coding Services',
    description: 'Contact us for you medical coding needs, we offer ICD, CPT and HCPCS coding services with our best medical coding team',
    points: [
      {
        descriptions: [
          {
            description: 'We help maximize revenue collections and boost revenue substantially'
          },
          {
            description: 'We are a team of certified coders having in-depth knowledge and expertise in using codes'
          },
        ],
        image: imagesArr.MedicalCodingService.MCS1,
      },
      {
        descriptions: [
          {
            description: 'We understand the importance of coding accuracy in reducing claim denials and rejections'
          },
          {
            description: 'We ensure error-free coding'
          },
          {
            description: 'We ensure that regular updates are sent to the provider.'
          },
        ],
        image: imagesArr.MedicalCodingService.MCS3,
      },
    ],
    type: 'b',
  },
  {
    image: imagesArr.CredentialEnrollment,
    title: 'Insurance Credentialing & Enrollment Services',
    description: 'We at 7th SkyMB provide insurance credentialing & Enrollment services to multi-specialty groups, solo practitioners, & physicians. We use the latest technology to ensure accuracy & customize the services according to the needs of the medical practitioner.',
    points: [
      {
        descriptions: [
          {
            description: 'We help in improving practice management & revenue strategies.'
          },
          {
            description: 'We help in the elimination of penalties.'
          },
        ],
        image: imagesArr.InsuranceCredentialingEnrollmentServices.ICES1,
      },
      {
        descriptions: [
          {
            description: 'We make sure to smoothly conform to all the compliance standards.'
          },
          {
            description: 'We help in collaborating with the insurers.'
          },
          {
            description: 'We help in making the transition hiccups-free.'
          },
        ],
        image: imagesArr.InsuranceCredentialingEnrollmentServices.ICES3,
      },
    ],
    type: 'b',
  },
  {
    image: imagesArr.PatientStatement,
    title: 'A/R Follow-Up',
    description: 'We at 7th SkyMB helps healthcare organizations in recovering overdue payments from insurance carriers easily and on time. So, here is when accounts receivable AR follow-up come into the picture.',
    points: [
      {
        title: "Why you need to have an A/R Team:",
        descriptions: [
          {
            description: 'To keep an eye on the denied claims',
          },
          {
            description: 'To receive maximum reimbursements from the payers',
          },
          {
            description: 'To make sure all the other processes like charge entry, verification & payment posting are done on time.',
          },
          {
            description: 'To complete the follow-up on time & with less cost.',
          },
        ],
        image: imagesArr.ARFollowup.AR1,
      },
      {
        title: "Our Contribution:",
        descriptions: [
          {
            description: 'We provide help to your practice in achieving financial stability',
          },
          {
            description: 'We provide help in recovering the overdue payments without any hassles.',
          },
          {
            description: '7th SkyMB helps in minimizing the time for outstanding payments.',
          },
          {
            description: 'Our team make sure that claims do not go missing.',
          },
          {
            description: 'Our dedicated team helps in recovering the accumulated payments not touched due to a lack of information.',
          },
        ],
        image: imagesArr.ARFollowup.AR2,
      }
    ],
    type: 'c',
  },
  {
    image: imagesArr.ClaimsRecheck,
    title: 'Revenue Cycle Management',
    description: '7th SkyMB is here to help you save revenue and achieve maximum reimbursements for all the rendered services. Our end-to-end Revenue Cycle Management services consist of the following:',
    points: [
      {
        title: 'Patient Appointments',
        descriptions: 'help in maintaining a smooth patient influx and operate the clinic in an efficient manner. We fix appointments on time in an organized manner and reduce patient waiting time significantly',
        image: imagesArr.RevenueCycleManagement.RCM1,
      },
      {
        title: 'Medical Eligibility Verification',
        descriptions: "We help you determine the exact amount a patient owes in the form of Copays/Coinsurance/Deductibles. This also helps reveal the patient's payment history.",
        image: imagesArr.RevenueCycleManagement.RCM2,
      },
      {
        title: 'Traditional & Latest Coding',
        descriptions: 'Our certified coders (CPC, COC, CIC, CPC-P, CPM, CPMA, CPCO, CPC-H, etc.) take care of specific specialties and ICD-10 coding. They assist in documenting appropriate CPT and ICD codes and Modifiers. We also educate the practice about procedures that can be billed together along with a particular treatment or accompanying medical services under NCCI database Guidelines.',
        image: imagesArr.Coding,
      },
      {
        title: 'Demographics / Charge Entry',
        descriptions: 'Our billing experts take care of the demographics and charge entry. They help the medical practice track claims to understand when and where the claims go and keep them informed about the filing of claims within 24 - 48 hours.',
        image: imagesArr.ChargesEntry,
      },
      {
        title: 'Electronic Claims Submission',
        descriptions: "We maintain a 99% clean claim standard. The trends and analysis are shared with the Coding and Claims entry teams along with the client as per the client's convenience during the review meeting",
        image: imagesArr.ClaimsRecheck,
      },
      {
        title: 'Payment Posting',
        descriptions: 'The payments received through ERA/EOB are posted on the system within 24 - 48 hours and reports are reconciled on a daily basis. Denials are captured and moved to the denial analysis team.',
        image: imagesArr.PaymentUpdating,
        highlights: [
          {
            highlight: "Two levels of quality audit to ensure the process is at par with international standards",
          },
          {
            highlight: "Trained staff that understands patient responsibility too, such as secondary balance, etc."
          },
        ]
      },
      {
        title: 'Denial Analysis & Resolutions',
        descriptions: "Claim denials are analyzed carefully and the root cause is defined for each problem. It is then escalated to the concerned teams to make sure the denials are fixed and ensure they don't recur in the future. Denial analysis is shared with the client once a week.",
        image: imagesArr.DenialApproval,
        highlights: [
          {
            highlight: "Deal with denials within 72 hours of receipt"
          },
          {
            highlight: "Detect the trend and track the percentage of denials daily"
          },
          {
            highlight: "Meticulous system to avoid disruption in revenue inflow"
          },
        ]
      },
      {
        title: 'Accounts Receivable Follow-up',
        descriptions: 'We keep track of every dollar on the table. We check with insurance companies for outstanding claims over 30 days through AR calling. The trend and details of the report are shared with the practice once a week/month',
        image: imagesArr.ARFollowup.AR1,
      },
      {
        title: 'Monthly Reporting',
        descriptions: 'We send a monthly report with a detailed financial summary, procedure productivity, collections reports (with the adjustments). We provide details of insurance-wise financials, rejections, denials, and AR reports along with the practice performance report.',
        extra: "As per special requirements of clients, customized reports are generated",
        image: imagesArr.AnalyticsReporting,
      },
      {
        title: 'Monthly Patient Statements',
        descriptions: 'We help you streamline your collections process by sending monthly statements to patients. We appraise the patients on time and regularly help them organize the payable amount.',
        extra: "We also handle patient calls and take payments over the phone",
        image: imagesArr.PatientStatement,
      },
    ],
    type: 'd'
  }
]

export const Services = ({ setService }) => {
  return (
    <div className='services-container'>
      <section id='history'>
        <div className='history'>
          <div className='history-left'>
            <p>Introduction</p>
            <span>7th Sky Medical Billing Services</span>
          </div>
          <div className='history-right'>
            <h3>Our experts have many years of combined experience in the industry and have helped hundreds of medical providers save time and increase profits</h3>
          </div>
        </div>
      </section>
      <section id='why'>
        <div className='why-container'>
          <p>Why choose 7th Sky?</p>
          <span>HIPAA Compliant</span>
          <h3>
              Our company provides all-inclusive, seamless billing services from
              start to finish so you can focus on running your practice and
              we're fully HIPAA compliant. We offer pricing plans to suit your
              needs, so you can find the one that best meets your budget. We
              guarantee to achieve the highest recovery on contractually due
              amounts from payers with our dedicated staff. Our commitment to
              compliance and superior customer service is unrivaled in the
              industry.
          </h3>
        </div>
      </section>
      <section id='services'>
        <div className='services'>
          <p>Our Services</p>
          <span>What we do</span>
          <div className='services-cards'>
            <ul>
              {
                changedServices.map((service, index) => (
                  <Link key={index} to={'./detail'} onClick={() => setService({ 
                    ServiceName: service.title,
                    ServiceDescription: service.description,
                    Points: service.points,
                    Type: service.type,
                   })}>
                    <ServiceCard key={index} img={service.image} title={service.title} description={service.description} />
                  </Link>
                ))
              }
              {/* {Object.keys(services).map((service, index) => (
                <Link to={`/${services[service].description}`}>
                  <ServiceCard key={index} img={services[service].image} title={services[service].title} description={services[service].description} />
                </Link>
              ))} */}
            </ul>
          </div>
        </div>
      </section>
    </div>
  )
}
