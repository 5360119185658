import React from 'react'
import { imagesArr } from '../../assets'

export const TitlePointHighlightCard = ({ title, descriptions, image, highlights, extra, direction }) => {
  return (
    <div className={`point-card-container ${direction}`}>
      <img src={image} alt="" />
      <div className='side'>
        <h3>{title}</h3>
        <p>{descriptions}</p>
        {
          highlights && (
            <>
              <h4>Service Highlights:</h4>
              <ul>
                {
                  highlights.map((highlight, index) => (
                    <div className='column'>
                      <img className='check' src={imagesArr.Check} alt='' />
                      <li key={index}><p>{highlight.highlight}</p></li>
                    </div>
                    ))
                  }
              </ul>
            </>
          )
        }
        {
          extra && <span>{extra}</span>
        }
      </div>
    </div>
  )
}