import React from 'react'

export const TitlePointCard = ({ info, image, direction }) => {
  return (
    <div className={`point-card-container ${direction}`}>
      <img src={image} alt="" />
      <div className='row'>
        {
          info.map((point, index) => (
            <div className='side' key={index}>
              <h3>{point.title}</h3>
              <p>{point.description}</p>
            </div>
          ))
        }
      </div>
    </div>
  )
}