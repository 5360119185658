import React from 'react'
import './HomeCard.css'

export const HomeCard = ({ image, title, subtitle, description }) => {
  return (
    <div className='card-container'>
      <div className='card-header'>
        <div className='img'>
          <img src={image} alt="property" />
        </div>
        <div className='card-header__title'>
          <h3>{title}</h3>
          <p>{subtitle}</p>
        </div>
      </div>
      <span>{description}</span>
    </div>
  )
}